import React, { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from '../../Assets/Home/ContactUs.png';
import emailjs from 'emailjs-com';

const ContactUs = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    const initialState = {
        FirstName: "",
        LastName: "",
        email: "",
        phone: "",
        city: "",
    };
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const formref = useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value, [`${name}Err`]: "" }));
    };

    const clearState = () => setState({ ...initialState });

    const handleSendMessage = (e) => {
        e.preventDefault();
        const { FirstName, LastName, email, phone, city } = state;
        let valid = true;

        if (!FirstName) {
            setState((prevState) => ({ ...prevState, nameErr: "FirstName is required" }));
            valid = false;
        }
        if (!LastName) {
            setState((prevState) => ({ ...prevState, phoneErr: "LastName is required" }));
            valid = false;
        }
        // if (!email) {
        //     setState((prevState) => ({ ...prevState, emailErr: "Email is required" }));
        //     valid = false;
        // }
        if (!phone) {
            setState((prevState) => ({ ...prevState, messageErr: "Phone is required" }));
            valid = false;
        }
        if (!city) {
            setState((prevState) => ({ ...prevState, messageErr: "City is required" }));
            valid = false;
        }

        if (valid) {
            setLoading(true);
            emailjs.sendForm("service_2tsld5f", "template_thrrq1d", formref.current, "WdElai6v8DZV8rHDv")
                .then(
                    (result) => {
                        console.log(result.text);
                        clearState();
                        setLoading(false);
                        setSuccess("Thanks for reaching out. We'll get back to you soon.😊");
                        setTimeout(() => {
                            // toggleModal();
                            setSuccess("")
                        }, 4000);
                    },
                    (error) => {
                        console.log(error.text);
                        setLoading(false);
                    }
                );
        }
    };
    return (
        <div className='overflow-x-hidden'>
            <div className='flex flex-col md:flex-row bg-[#FFF4F3] px-6 md:px-16'>

                <div
                    className='w-full md:w-[50%] mt-6 md:mt-12'
                    data-aos="fade-right"
                >
                    <img src={Contact} alt='Contact' className='mx-auto w-full h-auto' />
                </div>

                {/* Right side form section */}
                <form
                    ref={formref}
                    onSubmit={handleSendMessage}
                    className='w-full md:w-[50%] space-y-5 mt-8 md:mt-24'
                    data-aos="fade-left"
                >
                    <div className='space-y-5'>
                        <h3 className='text-lg md:text-xl tracking-wide text-start' data-aos="fade-down">
                            ----- Contact Us
                        </h3>
                        <h1 className='text-3xl md:text-5xl tracking-wide' data-aos="fade-up">
                            <span className='text-[#831723]'>Get in Touch</span> With Us
                        </h1>
                    </div>
                    <div className='space-y-7' data-aos="fade-up" data-aos-delay="200">
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <input
                                type='text'
                                name='FirstName'
                                value={state?.FirstName}
                                onChange={handleChange}
                                className='p-3 rounded-md'
                                placeholder='First Name'
                                data-aos="fade-up"
                                data-aos-delay="300" />
                            <input
                                type='text'
                                name='LastName'
                                value={state?.LastName}
                                onChange={handleChange}
                                className='p-3 rounded-md'
                                placeholder='Last Name'
                                data-aos="fade-up"
                                data-aos-delay="400" />
                        </div>
                        <div className='grid grid-cols-1'>
                            <input
                                type='email'
                                name='email'
                                value={state?.email}
                                onChange={handleChange}
                                className='p-3 rounded-md'
                                placeholder='Email'
                                data-aos="fade-up"
                                data-aos-delay="500" />
                        </div>
                        <div className='grid grid-cols-1'>
                            <input
                                type='tel'
                                name='phone'
                                value={state?.phone}
                                onChange={handleChange}
                                className='p-3 rounded-md'
                                placeholder='Phone Number'
                                data-aos="fade-up"
                                data-aos-delay="600" />
                        </div>
                        <div className='grid grid-cols-1'>
                            <input
                                type='text'
                                name='city'
                                value={state?.city}
                                onChange={handleChange}
                                className='p-3 rounded-md'
                                placeholder='City'
                                data-aos="fade-up"
                                data-aos-delay="700" />
                        </div>
                        <div className='flex justify-between items-center'>
                            <button
                                type='submit'
                                className='py-3 w-28 bg-[#831723] rounded-md text-white text-lg md:text-xl tracking-wide flex justify-center items-center' data-aos="fade-up" data-aos-delay="800">
                                {loading ? <div className="spinner"></div> : "Submit"}
                            </button>
                            <div className="text-green-500 text-center tracking-wider">
                                {success && success}
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactUs;
