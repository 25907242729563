import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import about from '../../Assets/Home/aboutimg.png';

const AboutOneWorld = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
        });
    }, []);

    return (
        <div className='overflow-x-hidden'>
            <div className='flex flex-col md:flex-row px-4 md:px-16 mt-6 gap-6'>
                {/* Left side text section */}
                <div
                    className='w-full md:w-[50%] flex items-center justify-center'
                    data-aos="fade-right"
                >
                    <div className='space-y-5'>
                        <h3 className='text-lg md:text-xl tracking-wide' data-aos="fade-down" data-aos-delay="200">
                            ----- About Us
                        </h3>
                        <h1 className="tracking-wide text-3xl md:text-4xl lg:text-5xl leading-relaxed md:leading-loose" data-aos="fade-up" data-aos-delay="300">
                            Luxury Redefined in <br /> Bhubaneswar
                        </h1>

                        <p className='tracking-wide text-sm md:text-base lg:text-lg' data-aos="fade-up" data-aos-delay="400">
                            Discover the ultimate in luxury living at One World Bhubaneswar, an iconic project that seamlessly blends modern design with timeless elegance. Situated along NH 16 in South Bhubaneswar, this landmark development is poised to redefine the city's skyline.
                        </p>
                        <button className='p-3 border-2 border-[#831723] mt-3 rounded-md text-[#831723]' data-aos="fade-up" data-aos-delay="500">
                        Inquiry Now
                        </button>
                    </div>
                </div>
                {/* Right side image section */}
                <div
                    className='w-full md:w-[50%]'
                    data-aos="fade-left"
                    data-aos-delay="600"
                >
                    <img className='mx-auto w-full h-auto' src={about} alt='About One World' />
                </div>
            </div>
        </div>
    );
};

export default AboutOneWorld;
