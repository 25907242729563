import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bhk from '../../Assets/Home/bhk.png';
import bhklogo from '../../Assets/Home/bhklogo.png';
import bhk1 from '../../Assets/Home/bhk2.png';
import bhk2 from '../../Assets/Home/bhk3.png';

const Bhkroom = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000, 
          easing: 'ease-in-out',
          once: false, 
        });
      }, []);

    return (
        <React.Fragment>
            <div className="space-y-4 mt-10">
                <h1 className="text-center tracking-wide text-3xl md:text-4xl lg:text-5xl" data-aos="fade-up">
                    Premium 2 and 3 BHK Flats
                </h1>
                <p className="text-center tracking-wide text-sm md:text-base lg:text-lg mx-6 md:mx-16" data-aos="fade-up">
                    Offering premium 2 and 3 BHK flats, One World Bhubaneswar is designed for individuals and families seeking
                    <br className="hidden md:inline"/> a lifestyle of elegance and convenience. Each apartment is meticulously crafted with top-of-the-line fittings
                    <br className="hidden md:inline"/> and finishes to provide a comfortable and modern living experience.
                </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10 md:px-16 px-6">
                <div className="shadow-md rounded-md bg-[#FFF4F3]" data-aos="zoom-in">
                    <img className="p-4 w-[100%]" src={bhk} alt="bhk" />
                    <div className="flex justify-between p-4">
                        <h1 className="text-[#831723] text-xl lg:text-2xl px-5">2 BHK</h1>
                        <img className="w-16 lg:w-24" src={bhklogo} alt="logo" />
                    </div>
                </div>
                <div className="shadow-md rounded-md bg-[#FFF4F3]" data-aos="zoom-in">
                    <img className="p-4 w-[100%]" src={bhk1} alt="bhk" />
                    <div className="flex justify-between p-4">
                        <h1 className="text-[#831723] text-xl lg:text-2xl">3 BHK</h1>
                        <img className="w-16 lg:w-24" src={bhklogo} alt="logo" />
                    </div>
                </div>
                <div className="shadow-md rounded-md bg-[#FFF4F3]" data-aos="zoom-in">
                    <img className="p-4 w-[100%]" src={bhk2} alt="bhk" />
                    <div className="flex justify-between p-4">
                        <h1 className="text-[#831723] text-xl lg:text-2xl">2 BHK</h1>
                        <img className="w-16 lg:w-24" src={bhklogo} alt="logo" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Bhkroom;

