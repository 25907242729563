import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Galleryimg1 from '../../Assets/Home/Galleryimg1.png';
import Galleryimg2 from '../../Assets/Home/Galleryimg2.png';

const OurGallery = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: false,   
    });
  }, []);

  return (
    <div className='overflow-x-hidden'>
      <div>
        <h1 className='text-5xl text-center mt-10' data-aos="fade-up">Our Gallery</h1>
        <div className='flex flex-col sm:flex-row justify-between px-4 sm:px-8 lg:px-16 mt-10 gap-8'>
          <div className="flex justify-center" data-aos="zoom-in">
            <img src={Galleryimg1} alt='Galleryimg1' className='w-full' />
          </div>
          <div className="flex justify-center" data-aos="zoom-in">
            <img src={Galleryimg2} alt='Galleryimg2' className='w-full' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurGallery;
