import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const WelcomeTo = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000,  
          once: false,   
        });
      }, []);

    return (
        <div className='overflow-x-hidden'>
            <div className='mt-10 space-y-5'>
                <h1 
                    className='text-3xl md:text-4xl lg:text-5xl tracking-wide text-center px-4 md:px-8 lg:px-16'
                    data-aos="fade-up"
                >
                    A Joint <strong className='text-[#831723]'> Venture </strong> by Metro Group <br /> and AcreRise
                </h1>
                <p 
                    className='text-center tracking-wide text-sm md:text-base lg:text-lg mx-6 md:mx-16'
                    data-aos="fade-up"
                >
                    One World Bhubaneswar is the result of a prestigious partnership between the Metro Group and AcreRise, bringing <br /> together a legacy of excellence in construction and design. This collaboration ensures that every aspect of the project <br /> exudes quality, sophistication, and comfort.
                </p>
                <iframe 
                    className='w-full h-64 md:h-96 lg:h-[40rem] px-4 md:px-8 lg:px-16 rounded-md' 
                    src="https://www.youtube.com/embed/o_aIle5vqW0?si=bIWavvFkdJEG9C2L" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                    data-aos="zoom-in"
                    data-aos-delay="400"
                >
                </iframe>
            </div>
        </div>
    );
}

export default WelcomeTo;
