import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import HeroSection from '../../Components/Home/HeroSection';
import AboutOneWorld from '../../Components/Home/AboutOneWorld';
import ContactUs from '../../Components/Home/ContactUs';
import WelcomeTo from '../../Components/Home/WelcomeTo';
import Features from '../../Components/Home/Features';
import OurGallery from '../../Components/Home/OurGallery';
import CollegeUniversities from '../../Components/Home/CollegeUniversities';
import Bhkroom from '../../Components/Home/Bhkroom';

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className='overflow-x-hidden'>
      <HeroSection data-aos="fade-up" />
      <section id='about'>
        <AboutOneWorld data-aos="fade-left" />
        <ContactUs data-aos="fade-right" />
      </section>
      <WelcomeTo data-aos="zoom-in" />
      <section id='Amenities'>
        <Features data-aos="fade-up" />
        <section id='bhkRooms'>
          <Bhkroom data-aos="fade-up" />
        </section>
      </section>
      <section id='Gallery'>
        <OurGallery data-aos="zoom-in" />
      </section>
      <section id='Location'>
        <CollegeUniversities data-aos="fade-up" />
      </section>
    </div>
  );
}

export default Home;