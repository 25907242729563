import React, { useRef, useState } from 'react';
import logo2 from '../../Assets/Home/log02.png';
import emailjs from 'emailjs-com';

const InquiryModal = ({ toggleModal }) => {
    const initialState = {
        FirstName: "",
        LastName: "",
        email: "",
        phone: "",
        city: "",
    };
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const formref = useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value, [`${name}Err`]: "" }));
    };

    const clearState = () => setState({ ...initialState });

    const handleSendMessage = (e) => {
        e.preventDefault();
        const { FirstName, LastName, email, phone, city } = state;
        let valid = true;

        if (!FirstName) {
            setState((prevState) => ({ ...prevState, nameErr: "FirstName is required" }));
            valid = false;
        }
        if (!LastName) {
            setState((prevState) => ({ ...prevState, phoneErr: "Phone is required" }));
            valid = false;
        }
        // if (!email) {
        //     setState((prevState) => ({ ...prevState, emailErr: "Email is required" }));
        //     valid = false;
        // }
        if (!phone) {
            setState((prevState) => ({ ...prevState, messageErr: "Message is required" }));
            valid = false;
        }
        if (!city) {
            setState((prevState) => ({ ...prevState, messageErr: "Message is required" }));
            valid = false;
        }

        if (valid) {
            setLoading(true);
            emailjs.sendForm("service_2tsld5f", "template_thrrq1d", formref.current, "WdElai6v8DZV8rHDv")
                .then(
                    (result) => {
                        console.log(result.text);
                        clearState();
                        setLoading(false);
                        setSuccess("Thanks for reaching out. We'll get back to you soon.😊");
                        setTimeout(() => {
                            toggleModal();
                        }, 4000);
                    },
                    (error) => {
                        console.log(error.text);
                        setLoading(false);
                    }
                );
        }
    };

    return (
        <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 sm:p-8">
            <form ref={formref} className="w-full max-w-2xl space-y-5 bg-black rounded-lg p-6 md:p-10 relative" onSubmit={handleSendMessage}>
                {/* Cross Icon for Closing Modal */}
                <button onClick={toggleModal} className="absolute top-4 right-4 text-white text-2xl font-bold focus:outline-none">
                    &times;
                </button>

                <h1 className="text-white text-center text-2xl md:text-3xl font-bold">Get in Touch With Us</h1>

                <div className="space-y-6">
                    {/* Input Fields */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <input
                            type="text"
                            className="w-full p-3 rounded-md text-black"
                            placeholder="First Name"
                            name="FirstName"
                            value={state.FirstName}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            className="w-full p-3 rounded-md text-black"
                            placeholder="Last Name"
                            name="LastName"
                            value={state.LastName}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="grid grid-cols-1">
                        <input
                            type="email"
                            className="w-full p-3 rounded-md text-black"
                            placeholder="Email"
                            name="email"
                            value={state.email}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="grid grid-cols-1">
                        <input
                            type="tel"
                            className="w-full p-3 rounded-md text-black"
                            placeholder="Phone Number"
                            name="phone"
                            value={state.phone}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="grid grid-cols-1">
                        <input
                            type="text"
                            className="w-full p-3 rounded-md text-black"
                            placeholder="City"
                            name="city"
                            value={state.city}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Success Message */}
                    <div className="text-green-300 text-center tracking-wider">
                        {success && success}
                    </div>

                    {/* Submit Button and Logo */}
                    <div className="flex justify-between items-center">
                        <img src={logo2} alt="logo" className="w-20 sm:w-24 lg:w-32" />
                        <button
                            className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center justify-center"
                            type="submit"
                        >
                            {loading ? <div className="spinner"></div> : "Submit"}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default InquiryModal;