import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import buliding from '../../Assets/Home/buliding.png';
import collage from '../../Assets/Home/collage.png';

const CollegeUniversities = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  return (
    <div className='overflow-x-hidden'>
      <div className='flex flex-col md:flex-row mt-10 px-4 md:px-16'>
        <div
          className='md:w-[60%] w-full bg-[#923337] grid grid-cols-1 p-7 gap-4 md:rounded-l-xl rounded-l-xl md:rounded-none rounded-r-xl'
          data-aos="fade-right"
        >
          <div className='flex text-white space-x-4 md:space-x-7 items-center md:items-start flex-col md:flex-row space-y-5 md:space-y-0'>
            <img src={collage} alt='img' />
            <div className='flex flex-col justify-center space-y-1'>
              <h1>College & Universities</h1>
              <p>CV Raman Global University – 11 Minutes</p>
              <p>BIT – 13 Minutes</p>
              <p>IIT – 18 Minutes</p>
            </div>
          </div>
          <div className='flex text-white space-x-4 md:space-x-7 items-center md:items-start flex-col md:flex-row space-y-5 md:space-y-0'>
            <img src={collage} alt='img' />
            <div className='flex flex-col justify-center space-y-1'>
              <h1>Schools</h1>
              <p>Amrita Vidyalayam, Bhubaneswar – 6 Minutes</p>
              <p>Jupiter Public School – 7 Minutes</p>
              <p>St. Xaviers International School – 10 Minutes</p>
            </div>
          </div>
          <div className='flex text-white space-x-4 md:space-x-7 items-center md:items-start flex-col md:flex-row space-y-5 md:space-y-0'>
            <img src={collage} alt='img' />
            <div className='flex flex-col justify-center space-y-1'>
              <h1>Healthcare</h1>
              <p>Bagchi Sri Shankara Cancer Hospital – 10 Minutes</p>
              <p>AIIMS, Bhubaneswar – 15 Minutes</p>
              <p>AMRI Hospital Bhubaneswar – 16 Minutes</p>
            </div>
          </div>
          <div className='flex text-white space-x-4 md:space-x-7 items-center md:items-start flex-col md:flex-row space-y-5 md:space-y-0'>
            <img src={collage} alt='img' />
            <div className='flex flex-col justify-center space-y-0 md:space-y-1'>
              <h1>IT Hub</h1>
              <p>Deloitte – 26 Minutes</p>
              <p>CAPGEMINI – 33 Minutes</p>
              <p>Wipro Technologies – 34 Minutes</p>
            </div>
          </div>
        </div>
        <div
          className='md:w-[40%] w-full mt-4 md:mt-0 md:rounded-r-xl rounded-r-xl rounded-l-xl md:rounded-none overflow-hidden'
          data-aos="fade-left"
        >
          <img src={buliding} alt='img' className='w-full md:h-[40rem]' />
        </div>
      </div>
      <div className='space-y-5 mt-5'>
        <h1 className='text-center tracking-wide'>Disclaimer:</h1>
        <p className='text-center tracking-wide'>RERA Registration No.: RP/19/2024/01115 | www.rera.odisha.gov.in</p>
        <div className='md:w-[70%] mx-auto mt-10'>
          <p className='text-center tracking-wide md:text-base lg:text-lg mx-6 md'>
            “Codename Mesmerise” is just a project codename used for the Project marketing purpose only. Project name as registered in ORERA is “One World By Metro & AcreRise” Project Registration Number: RP/19/2024/01115. For more details visit rera.odisha.gov.in.The information, visuals, renders, and creative depictions contained herein are artistic impressions, indicative in nature, and are for general information purposes. No information given in this Website creates a warranty or expands the scope of any warranty that cannot be disclaimed under the applicable laws, except for what has been provided in the Agreement/Contract [c] 2020. All rights reserved. This Website is not a legal or commercial offering.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CollegeUniversities