import heroimg from '../../Assets/Home/topBanner2.png';
import Premium from '../../Assets/Home/premium.png'
import Acrerise from '../../Assets/Home/Acrerise.png'
import hero from '../../Assets/Home/home2.png'
import { useState } from 'react';
import InquiryModal from '../Layout/InquiryModal';
import './HeroSection.css';

const HeroSection = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleScroll = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        setMenuOpen(false);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <div className='md:block hidden'>
                <div
                    className='h-screen flex items-center justify-center flex-col'
                    style={{
                        backgroundImage: `url(${heroimg})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundposition: "right",
                    }}
                >
                    <div className='absolute text-white top-40 right-4 md:top-48 md:right-16 px-4 space-y-5 flex items-center justify-center flex-col'>
                        <h3
                            className='text-center tracking-wide text-xl md:text-2xl text-[#831723] font-bold'
                            data-aos="fade-up"
                        >
                            Strategic Location on NH 16
                        </h3>
                        <h1
                            className='text-4xl md:text-5xl lg:text-7xl tracking-wider text-center z-40'
                            data-aos="zoom-in"
                        >
                            Welcome to One <br /> World Bhubaneswar
                        </h1>
                        <img
                            onClick={() => handleScroll('bhkRooms')}
                            className='cursor-pointer'
                            src={Premium}
                            alt='Premium'
                            data-aos="fade-right"
                        />

                        <img
                            src={Acrerise}
                            alt='Acrerise'
                            data-aos="fade-left"
                        />
                    </div>

                </div>
            </div>
            <div className='md:hidden block'>
                <div
                    className='h-screen flex items-center justify-center flex-col'
                    style={{
                        backgroundImage: `url(${hero})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundposition: "right",
                    }}
                >
                    <div className='absolute text-black top-30 right-4 md:top-48 md:right-16 px-4 space-y-5 flex items-center justify-center flex-col'>
                        <h1 className='mt-12 text-3xl font-extrabold tracking-wider text-center z-40'>
                            Welcome to One <br /> World Bhubaneswar
                        </h1>

                        <h3
                            className='text-center tracking-wide text-lg text-[#831723] font-medium'
                        >
                            Strategic Location on NH 16
                        </h3>

                        <img
                            onClick={() => handleScroll('bhkRooms')}
                            className='cursor-pointer mb-2'
                            src={Premium}
                            alt='Premium'
                        />

                        <button
                            onClick={toggleModal}
                            className="golden-button">
                            <span class="golden-text">
                                Inquiry Today
                            </span>
                        </button>

                        <img
                            src={Acrerise}
                            alt='Acrerise'
                        />
                    </div>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <InquiryModal
                    toggleModal={toggleModal}
                />
            )}
        </>
    );
};

export default HeroSection;