import React from 'react';
import logo from '../../Assets/Home/logo.png'
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <React.Fragment>
            <footer className='bg-[#161516] py-10 mt-10'>
                <div className='flex justify-between px-16 items-center md:flex-row flex-col space-y-5'>
                    <div>
                        <img className='w-36' src={logo} alt='logo' />
                    </div>
                    <h1 className='text-xl md:text-5xl tracking-wide text-white'>Your Dream<br className='block md:hidden'/> Home Awaits</h1>
                    <div className='flex text-[#EFCF2A] space-x-10'>
                        <FaFacebook size={25} />
                        <FaInstagram size={25} />
                        <FaTwitter size={25} />
                        <FaYoutube size={25} />
                    </div>
                </div>
                <hr className='mt-10'/>
                <p className='text-white text-center mt-8'>© {currentYear} | Privacy Policy | Metro Acrerise Promoters Private Limited | All rights reserved</p>
            </footer>
            {/* <div
                className=' bg-black'
                style={{
                    backgroundImage: `url(${footerbg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className='flex justify-center py-4'>
                    <img className='w-[4rem] md:w-[5rem]' src={logo} alt='log' />
                </div>

                <div className='flex flex-col md:flex-row justify-between text-white w-full px-8 md:px-16 py-5'>
                    <div className='flex justify-center md:justify-start space-x-2 md:space-x-9 tracking-widest'>
                        <div className='text-lg md:text-xl tracking-wide'>Home</div>
                        <div className='text-lg md:text-xl tracking-wide'>About</div>
                        <div className='text-lg md:text-xl tracking-wide'>Games</div>
                        <div className='text-lg md:text-xl tracking-wide'>Leaderboard</div>
                    </div>
                    <h1 className='tracking-widest text-center md:text-right text-lg md:text-xl mt-5 md:mt-0'>
                        Copyright © {currentYear}. All rights reserved by Grow bet.
                    </h1>
                </div>

                <div className="text-sm text-center text-white tracking-widest pb-6">
                    Design & Develop by
                    <a href="https://www.webbocket.com/" className="ml-1 underline text-orange-500">
                        Web-Bocket
                    </a>
                </div>
            </div> */}
        </React.Fragment>
    );
}

export default Footer;