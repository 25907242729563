import React, { useState } from 'react';
import { HiOutlineMenuAlt3, HiX } from 'react-icons/hi';
import logo from '../../Assets/Home/logo.png';
import InquiryModal from './InquiryModal';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    // setSuccess("");
  };

  return (
    <React.Fragment>
      <header className="text-white tracking-wider absolute inset-0  ">
        <nav className="px-6 lg:px-8 flex items-center justify-between h-20 bg-[#00000060]">
          <div>
            <img src={logo} alt="Logo" className="h-16 object-contain w-auto" />
          </div>
          <div className="hidden md:flex space-x-6">
            <button
              className="hover:text-gray-200 transition duration-200 tracking-wider"
              onClick={() => handleScroll('about')}
            >
              About
            </button>
            <button
              className="hover:text-gray-200 transition duration-200 tracking-wider"
              onClick={() => handleScroll('Gallery')}
            >
              Gallery
            </button>
            <button
              className="hover:text-gray-200 transition duration-200 tracking-wider"
              onClick={() => handleScroll('Amenities')}
            >
              Amenities
            </button>
            <button
              className="hover:text-gray-200 transition duration-200 tracking-wider"
              onClick={() => handleScroll('Location')}
            >
              Location
            </button>
          </div>
          <div className="hidden md:block">
            <button
              className="tracking-wider border-2 text-white hover:text-white transition duration-200 px-4 py-2 rounded-full"
              onClick={toggleModal}
            >
              Inquiry Today
            </button>
          </div>
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu}>
              {menuOpen ? (
                <HiX className="h-6 w-6" />
              ) : (
                <HiOutlineMenuAlt3 className="h-6 w-6" />
              )}
            </button>
          </div>
        </nav>

        <div
          className={`fixed top-0 left-0 h-full w-64 bg-[#000000] text-white transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'
            } transition-transform duration-300 ease-in-out z-50`}
        >
          <div className="flex justify-between items-center p-4">
            <img src={logo} alt="Logo" className="h-10 w-auto" />
            <button onClick={toggleMenu} className="focus:outline-none">
              <HiX className="h-6 w-6" />
            </button>
          </div>
          <div className="flex flex-col mt-4 space-y-4 text-center">
            <button
              className="hover:text-gray-400 transition duration-200 tracking-wider"
              onClick={() => handleScroll('about')}
            >
              About
            </button>
            <button
              className="hover:text-gray-400 transition duration-200 tracking-wider"
              onClick={() => handleScroll('Gallery')}
            >
              Gallery
            </button>
            <button
              className="hover:text-gray-400 transition duration-200 tracking-wider"
              onClick={() => handleScroll('Amenities')}
            >
              Amenities
            </button>
            <button
              className="hover:text-gray-400 transition duration-200 tracking-wider"
              onClick={() => handleScroll('Location')}
            >
              Location
            </button>

            <button
              onClick={toggleModal}
              className="border-2 border-red-500 text-white hover:bg-red-500 hover:text-white transition duration-200 tracking-wider px-4 py-2 rounded-full"
            >
              Inquiry Today
            </button>
          </div>
        </div>

        {menuOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={toggleMenu}
          />
        )}
      </header>

      {/* Modal */}
      {isModalOpen && (
        <InquiryModal
          toggleModal={toggleModal}
        />
      )}
    </React.Fragment>
  );
};

export default Navbar;