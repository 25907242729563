import React from 'react';
import img from '../../Assets/Home/home.png'

const amenities = [
    { title: "Fitness Plaza", img: img },
    { title: "Featured Pavilions", img: img },
    { title: "Pickleball Court", img: img },
    { title: "Kid's Play Area", img: img },
    { title: "Yoga & Zumba", img: img },
    { title: "Lawn", img: img },
    { title: "Half Basketball Court", img: img },
    { title: "Swing Pavilion", img: img },
    { title: "Indoor Games", img: img },
    { title: "Floating Cabana", img: img },
    { title: "Indoor Squash Court", img: img },
    { title: "Jogging Track", img: img },
    { title: "Guest Suite", img: img },
    { title: "Bonfire Court", img: img },
    { title: "Indoor Badminton Court", img: img },
    { title: "Pool Deck", img: img },
    { title: "Courtside Club", img: img },
    { title: "Guest Suite", img: img },
];

const Features = () => {
  
    return (
        <div className="bg-[#FFF4F3] py-10 overflow-x-hidden">
            <div className='space-y-3'>
                <h3 className='text-center tracking-wide'>----- Features</h3>
                <h1 className='text-3xl md:text-4xl lg:text-5xl tracking-wide text-center px-4 md:px-8 lg:px-16'>
                    Unmatched <strong className='text-[#831723]'>Amenities</strong> for Modern Living
                </h1>
                <p className='text-center tracking-wide text-sm md:text-base lg:text-lg mx-6 md:mx-16'>
                    Step into a serene environment that allows you to relax and recharge while still being <br />connected to the city's vibrant energy.
                </p>
            </div>
            <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 mt-10 px-4">
                {amenities.map((amenity, index) => (
                    <div 
                        key={index} 
                        className="flex items-center space-x-4" 
                    
                    >
                        <div className="w-12 h-12 bg-red-700 rounded-full flex items-center justify-center">
                            <img
                                src={amenity.img}
                                alt={amenity.title}
                                className="h-6 w-6 object-contain"
                            />
                        </div>
                        <span className="text-lg font-medium text-gray-700">{amenity.title}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Features;
